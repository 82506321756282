import "./styles.css";
import Logo from "./logo.gif";
import React, { useState, useEffect, createContext } from "react";
import SelectCase from "./SelectCase";
import Paper from "@mui/material/Paper";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  useLocation,
  Link,
  Redirect,
  withRouter,
} from "react-router-dom";
import Esign from "./Esign";
import MainMenu from "./components/MainMenu";
import AdminMenu from "./components/AdminMenu";
import View from "./View";
import Register from "./Register";
import Registrations from "./components/Registrations";
import MergeOkta from "./MergeOkta";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import config from "./config";
import Navbar from "./components/Navbar";
import JSONPretty from "react-json-pretty";
import ComplianceReport from "./components/ComplianceReport";
import userMaintenance from "./components/UserMaintenance";

import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_USER_INFO, UPSERT_RDFS_USER } from "./Queries";

//const oktaAuth = new OktaAuth(config.oidc);
import { useOktaAuth } from "@okta/okta-react";

import * as Sentry from "@sentry/react";
import { BooleanSchema } from "yup";

function App() {
  const [corsErrorModalOpen, setCorsErrorModalOpen] = React.useState(false);
  const [authRequiredModalOpen, setAuthRequiredModalOpen] =
    React.useState(false);
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const homeDir = process.env.REACT_APP_HOME_DIR || "/";
  const history = useHistory();
  const location = useLocation();

  const [upsertUser] = useMutation(UPSERT_RDFS_USER);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
      //console.log('not authenticated');
    } else {
      oktaAuth
        .getUser()
        .then((info) => {
          let nav = true;
          setUserInfo(info);
          console.log(info);

          if (info != null) {
            console.log(userInfo);

            upsertUser({
              variables: {
                userEmail: info.email,
                userFrstNm: info.given_name,
                userLstNm: info.family_name,
              },
            });

            console.log(`User Info: ${JSON.stringify(info, null, 2)}`);
          }
          return nav;
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  /* Grab the security level from the database and add to userInfo. (TRG 7/21/22) */
  const userEmail = userInfo ? userInfo.email : null;
  const {
    loading: userInfoLoading,
    error: userInfoError,
    data: userInfoData,
  } = useQuery(GET_USER_INFO, {
    variables: { userEmail: userEmail },
    fetchPolicy: "network-only",
  });
  if (userInfoLoading) return "Loading...";
  if (userInfoError) return `Error! ${userInfoError.message}`;

  const userSecLvl =
    userInfoData.getUserInfo.length > 0
      ? userInfoData.getUserInfo[0].userSecLvl
      : null;
  const allUserInfo = { ...userInfo, userSecLvl: userSecLvl };
  //console.log(`All User Info: ${JSON.stringify(allUserInfo, null, 2)}`)

  const isAdmin = allUserInfo.userSecLvl === "AD" ? true : false;

  if (allUserInfo.userSecLvl != null) {
    console.log(userInfo);
  }

  const DebugRouter = ({ children }) => {
    const { location } = useHistory();
    if (process.env.REACT_APP_TEST_MODE === "true") {
      /*console.log(
        `Route: ${location.pathname}${location.search}, State: ${JSON.stringify(location.state)}`,
      )*/
    }

    return children;
  };

  return (
    <UserContext.Provider value={allUserInfo}>
      <div className="App">
        {userInfo && (
          <>
            <Navbar {...{ setCorsErrorModalOpen }} />
            <br />
          </>
        )}
        <div style={{ paddingLeft: 25, paddingRight: 25 }}>
          <DebugRouter>
            <Switch>
              <Route
                path={`${homeDir}login/callback`}
                component={LoginCallback}
              />
              <SecureRoute
                exact
                path={`${homeDir}`}
                component={withRouter(MainMenu)}
              />
              <SecureRoute
                exact
                path={`${homeDir}Registrations/:status`}
                component={withRouter(Registrations)}
              />
              <SecureRoute
                exact
                path={`${homeDir}SelectCase`}
                component={withRouter(SelectCase)}
              />
              <SecureRoute
                exact
                path={`${homeDir}Register/:caseId/:step`}
                component={withRouter(Register)}
              />
              {/*<SecureRoute exact path={`${homeDir}Register/:caseId`} component={withRouter(SelectCase)}/> 
                  todo: figure out how to have a separate route when they select the case */}
              <SecureRoute
                exact
                path={`${homeDir}Register`}
                component={withRouter(SelectCase)}
              />
              <SecureRoute
                exact
                path={`${homeDir}Amend/:caseId/:step`}
                component={withRouter(Register)}
              />
              <SecureRoute
                exact
                path={`${homeDir}Esign/:caseId`}
                component={withRouter(Esign)}
              />
              <SecureRoute
                exact
                path={`${homeDir}View/:documentId`}
                component={withRouter(View)}
              />
              {/*<SecureRoute exact path={`${homeDir}`} component={withRouter(MainMenu)}/>   */}
              <SecureRoute
                exact
                path={`${homeDir}Home`}
                component={withRouter(MainMenu)}
              />
              {/*Is there a way to prevent non-admins from accessing these routes? */}
              {isAdmin ? ( // if isAdmin is true, render the following components
                <>
                  <SecureRoute
                    exact
                    path={`${homeDir}Admin`}
                    component={withRouter(AdminMenu)}
                  />
                  <SecureRoute
                    exact
                    path={`${homeDir}Admin/compliance-report`}
                    component={withRouter(ComplianceReport)}
                  />
                  <SecureRoute
                    exact
                    path={`${homeDir}Admin/user-maintenance`}
                    component={withRouter(userMaintenance)}
                  />
                </>
              ) : (
                // else redirect to homepage
                <Redirect to="/" />
              )}

              {/* <SecureRoute exact path={`${homeDir}Admin`} component={withRouter(AdminMenu)}/>
                  <SecureRoute exact path={`${homeDir}Admin/compliance-report`} component={withRouter(ComplianceReport)}/>
                  <SecureRoute exact path={`${homeDir}Admin/user-maintenance`} component={withRouter(userMaintenance)}/> */}
              <Redirect from="" to="/Home" />
            </Switch>
          </DebugRouter>
        </div>
      </div>
    </UserContext.Provider>
  );
}

export const UserContext = createContext();
export default Sentry.withProfiler(App);
